<template>
  <!-- Modal dialog: Error -->
  <v-dialog 
    transition="dialog-top-transition" 
    v-model="dialog" width="500" 
    @keydown.esc="cancel" 
    persistent
  >
    <v-card-title class="dialogTitleClass bg-dialog-error">
      <v-icon class="mr-2 icon-error">mdi-close-circle</v-icon>{{ title }}
    </v-card-title>
    <v-card-text v-show="!!message" class="dialogTextClass" v-html="message"></v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn v-if="!options.noconfirm" class="btn-back" @click.native="cancel">
        <span class="s-text-white-bold">CERRAR</span>
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
  export default {
    name: "DialogError",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          noconfirm: false,
        },
      };
    },
    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
        this.$emit('refreshshowMap', false);
      },
    },
  };
</script>